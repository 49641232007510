<template>
  <looker-report :url="url" />
</template>

<script>
export default {
  components: {
    LookerReport: () => import('@/modules/external_bi/components/LookerReport')
  },
  data: () => ({
    url: 'https://lookerstudio.google.com/embed/reporting/711c9160-5b74-4a93-b8cd-239d10aad8fb/page/p_qb1bw0gvgd'
  })
}
</script>
